/**=====================
     category css start
==========================**/

@import "../utils/mixin/category";

@mixin category_common {
    .category-section {
        &.ratio_40 {
            .bg-size {
                &::before {
                    padding-top: 20%;
                }
            }
        }
    }

    .category-wrap {
        padding: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
        position: relative;
        height: 130px;
        display: flex;
        align-items: center;

        @include mq-max(2xs) {
            height: unset;
        }

        &.category-color {
            background-color: $light-blue;
        }

        &.category-padding {
            @include mq-max(3xl) {
                padding: 23px;
            }

            @include mq-max(xl) {
                padding: 19px;
            }
        }

        &.theme-bg-color {
            color: $white;
            background-color: var(--theme-color);

            .light-text {
                font-size: 72px;
                line-height: 96px;
                color: $white;
                font-weight: 700;
                margin-bottom: 0;
                position: absolute;
                opacity: 0.08;
                top: -10px;
                left: 0;

                [dir="rtl"] & {
                    left: unset;
                    right: 0;
                }
            }

            h2 {
                margin-bottom: 10px;

                &.top-spacing {
                    margin-top: -3px;
                }
            }

            span {
                letter-spacing: calc(2px + (8 - 2) * ((100vw - 320px) / (1920 - 320)));
                text-transform: uppercase;
                display: block;
                margin-bottom: -5px;
            }
        }

        .category-content {
            position: absolute;
            right: 30px;
            @include center(vertical);

            span {
                color: $black1;
                display: block;
                text-align: right;
            }

            [dir="rtl"] & {
                text-align: right;
            }

            &.category-text {
                text-align: right;

                h3 {
                    font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
                    margin-top: -1px;
                }

                span {
                    font-size: calc(12px + (18 - 12) * ((100vw - 320px) / (1920 - 320)));
                    display: block;
                    margin-bottom: -3px;
                }

                &-1 {
                    h3 {
                        margin-top: -4px;
                    }

                    span {
                        display: block;
                        margin-bottom: -6px;
                    }
                }

                &.category-spacing {
                    h3 {
                        margin-top: -3px;
                    }

                    span {
                        margin-bottom: -3px;
                    }
                }

                &.t-text {
                    h3 {
                        margin-top: -5px;
                        color: $black1;
                    }

                    span {
                        display: block;
                        margin-bottom: -5px;
                        color: $black1;
                    }
                }
            }

            h3 {
                margin-bottom: 2px;
                font-weight: 600;
                font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }
}

@mixin category_1 {
    .category-style-1 {
        width: 100%;
        position: relative;
        background-color: $red;

        img {
            width: 100px;
        }

        &.theme-bg-color {
            color: $white;

            .light-text {
                font-size: 72px;
                line-height: 96px;
                color: $white;
                font-weight: 700;
                margin-bottom: 0;
                position: absolute;
                opacity: 0.08;
                top: -10px;
                left: 0;
            }

            h2 {
                margin-bottom: 10px;
            }

            span {
                letter-spacing: 8px;
                text-transform: uppercase;
            }
        }

        .category-content {
            color: $black1;

            h3 {
                margin-bottom: 2px;
                font-weight: 600;
                font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
            }

            span {
                font-weight: 500;
            }

            &.category-spacing {
                h3 {
                    margin-top: -3px;
                }

                span {
                    margin-bottom: -3px;
                }
            }
        }
    }
}

@mixin category_2 {
    .category-style-2 {
        width: 100%;
        position: relative;
        background-color: $red;

        img {
            width: 100px;
        }

        &.theme-bg-color {
            color: $white;

            .light-text {
                font-size: 72px;
                line-height: 96px;
                color: $white;
                font-weight: 700;
                margin-bottom: 0;
                position: absolute;
                opacity: 0.08;
                top: -10px;
                left: 0;
            }

            h2 {
                margin-bottom: 10px;
            }

            span {
                letter-spacing: 8px;
                text-transform: uppercase;
            }
        }

        .category-content {
            color: $black1;

            &.category-spacing {
                h3 {
                    margin-top: -3px;
                }

                span {
                    margin-bottom: -3px;
                }
            }

            h3 {
                margin-bottom: 2px;
                font-weight: 600;
                font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
            }

            span {
                font-weight: 500;
            }
        }
    }
}

@mixin category_3 {
    .category-style-3 {
        .category-image-fruit {
            position: relative;
            padding-bottom: 50px;
            width: 90%;

            img {
                width: 100%;
            }

            @include mq-max(2xs) {
                padding-bottom: 30px;
            }

            .category-contain {
                position: absolute;
                bottom: 0;
                right: 0;

                img {
                    width: 100%;

                    [dir="rtl"] & {
                        margin-right: auto;
                    }

                    @include mq-max(md) {
                        margin-left: auto;
                    }
                }
            }

            .category-text {
                position: absolute;
                top: 0%;
                left: 0%;
                color: $white;
                color: white;
                top: 20px;
                left: 30px;

                [dir="rtl"] & {
                    left: unset;
                    right: 30px;
                    text-align: right;
                }

                h2 {
                    font-size: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
                    margin-bottom: 2px;
                    font-weight: 900;
                }

                h5 {
                    font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));
                }
            }
        }
    }
}