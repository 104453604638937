/**=====================
     alert css start
==========================**/
.alert-info {
    .btn-close {
        font-size: 10px;
    }
}

.progress-bar-info {
    background-color: $dark-green;
}

[data-notify="progressbar"] {
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
}