/**=====================
     Tap to top css start
==========================**/
.tap-to-top {
    background-color: var(--theme-color);
    position: fixed;
    bottom: 15px;
    right: -60px;
    border-radius: 5px;
    overflow: hidden;
    width: calc(35px + (45 - 35) * ((100vw - 320px) / (1920 - 320)));
    height: calc(35px + (45 - 35) * ((100vw - 320px) / (1920 - 320)));
    @include flex_common;
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    box-shadow: 0 3px 8px rgba($black1, $alpha: 0.35);
    z-index: 3;
    transition: all 0.5s ease;
    cursor: pointer;
    opacity: 0;

    [dir="rtl"] & {
        right: unset;
        left: -60px;
    }

    &::before {
        @include pos;
        width: 100%;
        height: 0;
        background: $black1;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
        transition: 0.5s;
    }

    &:hover {
        &::before {
            height: 100%;
        }
    }

    @include mq-max(sm) {
        bottom: 75px;
    }

    &.show {
        right: 15px;
        transition: all 0.5s ease;
        opacity: 1;

        [dir="rtl"] & {
            right: unset;
            left: 15px;
        }
    }

    a {
        color: $white;
        width: calc(35px + (40 - 35) * ((100vw - 320px) / (1920 - 320)));
        height: calc(35px + (40 - 35) * ((100vw - 320px) / (1920 - 320)));
        @include flex_common;
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        z-index: 3;

        @include mq-max(sm) {
            bottom: 75px;
        }
    }
}