/**=====================
    Lazy Load css Start
==========================**/
.blur-up {
    filter: blur(5px);
    transition: filter 400ms, -webkit-filter 400ms;

    &.lazyloaded {
        filter: blur(0);
    }
}