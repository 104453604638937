/**=====================
     nav css start
==========================**/

header {
    .main-navbar {
        text-align: center;

        .nav-menu {
            display: inline-block;
            list-style: none;
            margin: 0;
            padding: 0;
            line-height: normal;
            direction: ltr;
            @include rtl(text-align, left, right);
            vertical-align: middle;

            >li {
                position: relative;
                @include rtl(float, left, right);
                display: block;

                +li {
                    +li {
                        margin-left: 30px;

                        [dir="rtl"] & {
                            margin-left: unset;
                            margin-right: 30px;
                        }

                        @include mq-max(xl) {
                            margin-left: 0;

                            [dir="rtl"] & {
                                margin-left: unset;
                                margin-right: 0;
                            }
                        }
                    }
                }

                >a {
                    display: block;
                    color: $theme-font-color;
                    font-size: 14px;
                    font-weight: 500;
                    text-decoration: none;
                    text-transform: uppercase;
                    padding: 0;
                    position: relative;
                    border-radius: 15px;
                    letter-spacing: 0.8px;
                }

                &.active {
                    >a {
                        background-color: rgba($theme-font-color, 0.1);
                    }
                }

                .nav-submenu {
                    position: absolute;
                    left: 0;
                    z-index: 1;
                    box-shadow: 0 1px 5px 0 rgba(90, 90, 90, 0.2);
                    padding: 15px 0;
                    background: $white;
                    width: 300px !important;

                    [dir="rtl"] & {
                        left: unset;
                        right: 0;
                    }

                    li {
                        border: 0;
                        float: none;
                        position: relative;
                        display: block;
                        margin: 5px 0;

                        a {
                            border: 0 !important;
                            background: transparent;
                            color: $black1;
                            border-radius: 0 !important;
                            font-size: 14px;
                            text-transform: capitalize;
                            padding: 3px 25px 3px 40px;
                            display: block;
                            letter-spacing: 0.07em;
                            line-height: 1.9;
                            transition: all 0.5s ease;
                            position: relative;

                            [dir="rtl"] & {
                                padding: 3px 40px 3px 25px;
                            }

                            span {
                                padding: 0px 10px;
                                border-radius: 5px;
                                font-size: 12px;
                                color: var(--theme-color);
                                margin-left: 10px;
                                box-shadow: 0 8px 15px -8px var(--badge-color);
                                margin-left: 8px;
                                position: relative;

                                &::before {
                                    @include pos;
                                    @include pseudowh;
                                    top: 0;
                                    left: 0;
                                    background-color: var(--theme-color);
                                    opacity: 0.07;
                                }

                                &.tren-budge {
                                    color: $black1;
                                    background: $light-blue;
                                }

                                &.top-budge {
                                    color: $light-blue;
                                    background-color: $font-light;
                                }
                            }

                            .label {
                                font-size: 12px;
                                color: $red;
                                position: absolute;
                                padding: 0 0 0 3px;
                            }

                            &.menu-title-level1,
                            &.submenu-title {
                                &:after {
                                    opacity: 1;
                                }
                            }

                            &.arrow-cls {
                                &:after {
                                    opacity: 0.8;
                                }
                            }

                            &:hover {
                                margin-left: 3px;
                                color: var(--theme-color) !important;
                            }
                        }

                        .nav-sub-childmenu {
                            position: absolute;
                            width: 200px;
                            right: -200px;
                            top: 0;
                            background: $white;
                            border: none;
                            padding: 15px 0 15px 0;
                            box-shadow: 0 0 1px 0 $gray-50;
                        }
                    }
                }

                .mega-menu-container {
                    position: absolute;
                    left: 0;
                    z-index: 4;
                    box-shadow: 0 1px 5px 0 rgba(90, 90, 90, 0.2);
                    padding: 30px;
                    background: $white;
                    width: 100%;
                    border-radius: 4px;

                    &.poster-bg-image {
                        background-image: url(../images/voxo.png);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        overflow: hidden;

                        @include mq-max(xl) {
                            background-image: unset;
                        }

                        .floating-img {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 30%;
                            height: 60vh;
                            animation: floating 110s linear infinite reverse;
                            background-repeat: repeat-x !important;
                        }
                    }

                    .mega-box {
                        width: 16%;

                        @include mq-max(xl) {
                            width: 100%;
                        }

                        a {
                            .label {
                                font-size: 12px;
                                color: $red;
                                position: absolute;
                                padding: 0 0 0 3px;
                            }
                        }

                        .link-section {
                            .submenu-title {
                                h5 {
                                    font-weight: 500;
                                    text-transform: capitalize;
                                    font-size: 14px;
                                    margin-bottom: 14px;
                                }
                            }

                            .submenu-content {
                                box-shadow: none;
                                background-color: transparent;
                                position: relative;
                                display: block;
                                padding: 0;

                                ul {
                                    li {
                                        border: none;
                                        float: none;
                                        position: relative;
                                        display: block;
                                        margin: 5px 0;

                                        a {
                                            border: 0 !important;
                                            background: transparent;
                                            color: $black1;
                                            border-radius: 0 !important;
                                            font-size: 14px;
                                            text-transform: capitalize;
                                            padding: 3px 0;
                                            display: block;
                                            letter-spacing: 0.07em;
                                            line-height: 1.9;
                                            position: relative;

                                            @include mq-max(xl) {
                                                padding: 0;
                                                letter-spacing: 0.03em;
                                            }

                                            span {
                                                padding: 0px 10px;
                                                border-radius: 5px;
                                                font-size: 12px;
                                                color: var(--theme-color);
                                                background: rgba(226, 36, 84, 0.07);
                                                box-shadow: 0 8px 15px -8px var(--badge-color);
                                                margin-left: 8px;

                                                &.tren-budge {
                                                    color: $black1;
                                                    background: $light-blue;
                                                }

                                                &.top-budge {
                                                    color: $light-blue;
                                                    background-color: $font-light;
                                                }
                                            }

                                            img {
                                                width: 100%;
                                            }

                                            &:hover {
                                                margin-left: 4px;
                                                color: var(--theme-color) !important;
                                            }

                                            &.megamenu-image {
                                                width: 100%;
                                                height: 250px;
                                                overflow: hidden;

                                                img {
                                                    transition: all 0.3s ease;

                                                    @include mq-max(xl) {
                                                        filter: blur(0);
                                                        height: 180px;
                                                    }
                                                }

                                                &:hover {
                                                    margin-left: 0;

                                                    img {
                                                        filter: blur(0);
                                                        margin-left: 0;
                                                    }
                                                }
                                            }
                                        }

                                        &.highlight-link {
                                            position: relative;

                                            i {
                                                color: var(--theme-color);
                                            }
                                        }
                                    }
                                }
                            }

                            +.link-section {
                                margin-top: 25px;
                            }
                        }
                    }
                }

                &.mega-menu {
                    position: unset;

                    &.home-menu {
                        .mega-menu-container {
                            .mega-box {
                                .link-section {
                                    ul {
                                        float: none;
                                        position: relative;
                                        display: block;
                                        margin: 5px 0;
                                        padding: 0;
                                        box-shadow: none;
                                    }
                                }
                            }
                        }

                        ul {
                            li {
                                width: 100%;

                                .megamenu-image {
                                    @include pseudowh;
                                    overflow: hidden;
                                    display: block;
                                    padding: 0;

                                    @include mq-max(xl) {
                                        height: 180px;
                                    }

                                    img {
                                        width: 100%;
                                        transition: all 0.3s ease;

                                        @include mq-max(xl) {
                                            filter: blur(0);
                                        }
                                    }

                                    &:hover {
                                        margin-left: 0;

                                        img {
                                            filter: blur(0);
                                            margin-left: 0;
                                        }
                                    }
                                }
                            }
                        }

                        .megamenu-image-title {
                            cursor: pointer;
                            padding: 10px;
                            text-align: center;

                            @include mq-max(xl) {
                                text-align: center;
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .responsive-btn {
        display: none;
    }

    header {
        .main-navbar {
            .nav-menu {
                >li {
                    .nav-submenu {
                        width: 250px;
                        opacity: 0;
                        visibility: hidden;
                        border-radius: 5px;
                        transition: all 0.5s ease;

                        a {
                            .sub-arrow {
                                position: absolute;
                                right: 20px;
                                top: 7px;
                                color: rgba(0, 0, 0, 0.75);
                                font-size: 10px;
                                transition: all 0.5s ease;
                            }
                        }

                        li {
                            .level1 {
                                display: none;
                                border-radius: 4px;

                                li {
                                    .level2 {
                                        display: none;
                                        border-radius: 4px;

                                        li {
                                            .level3 {
                                                display: none;
                                            }

                                            &:hover {
                                                .level3 {
                                                    display: block;
                                                }
                                            }
                                        }
                                    }

                                    &:hover {
                                        .level2 {
                                            display: block;
                                        }
                                    }
                                }
                            }

                            &:hover {
                                .level1 {
                                    display: block;
                                }
                            }

                            a {
                                &:hover {
                                    .sub-arrow {
                                        right: 16px;
                                        color: $black1;
                                        transition: all 0.5s ease;
                                    }
                                }
                            }
                        }
                    }

                    &.dropdown {
                        .menu-title {
                            position: relative;
                            padding-right: 18px;

                            &::after {
                                position: absolute;
                                content: "\f078";
                                font-family: "Font Awesome 5 Free";
                                right: 0;
                                @include center(vertical);
                                font-weight: 600;
                                font-size: 12px;
                            }
                        }
                    }

                    .mega-menu-container {
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.5s ease;
                    }

                    &:hover {
                        .nav-submenu {
                            opacity: 1;
                            z-index: 4;
                            visibility: visible;
                            margin-top: 28px;
                            transition: all 0.5s ease;
                        }

                        .mega-menu-container {
                            opacity: 1;
                            visibility: visible;
                            margin-top: 28px;
                            transition: all 0.5s ease;
                        }
                    }
                }
            }
        }
    }

    .toggle-nav {
        display: none;
    }

    .mobile-back {
        display: none !important;
    }
}

@include mq-max(xl) {
    header {
        .main-navbar {
            .nav-menu {
                position: fixed;
                background-color: $white;
                width: 300px;
                border-radius: 0;
                padding-bottom: 155px;
                top: 0;
                height: 100vh;
                right: -300px;
                z-index: 99;
                transition: all 0.3s ease;
                overflow-y: scroll;
                overflow-x: hidden;

                &.opennav {
                    right: 0;
                }

                .back-btn {
                    margin-bottom: 10px;

                    .mobile-back {
                        cursor: pointer;

                        i {
                            font-size: 14px;
                            transform: rotate(180deg);
                        }
                    }
                }

                >li {
                    display: block;
                    float: none;
                    width: 100%;
                    padding: 0;

                    &:nth-child(n + 2) {
                        border-bottom: 1px solid rgba($gray-light, $alpha: .5);
                        margin-bottom: 10px;
                        padding-bottom: 10px;
                    }

                    a {
                        padding: 10px 15px;
                        position: relative;

                        .sub-arrow {
                            right: 20px;
                            position: absolute;

                            &:before {
                                content: "+";
                                font-family: inherit;
                                position: relative;
                            }
                        }
                    }

                    >ul {
                        li {
                            a {
                                .according-menu {
                                    top: 2px;
                                }
                            }

                            ul {
                                li {
                                    a {
                                        .according-menu {
                                            top: 1px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .nav-submenu {
                        width: 100%;
                        padding: 0;
                        position: relative;
                        display: none;
                        box-shadow: none;

                        li {
                            a {
                                position: relative;

                                &:after {
                                    @include pos;
                                    @include center(vertical);
                                    left: 20px;
                                    width: 3px;
                                    height: 3px;
                                    border-radius: 50%;
                                    background-color: $black1;

                                    [dir="rtl"] & {
                                        left: unset;
                                        right: 20px;
                                    }
                                }

                                &:hover {
                                    &::after {
                                        background-color: var(--theme-color);
                                    }
                                }
                            }
                        }

                        &.opensubmenu {
                            display: block;
                        }

                        li {
                            .nav-sub-childmenu {
                                display: none;
                                position: relative;
                                right: 0;
                                width: 100%;
                                padding: 0;
                                box-shadow: none;

                                &.submenu-content {
                                    &.level2 {
                                        li {
                                            a {
                                                padding: 0 60px;
                                            }
                                        }

                                        .level3 {
                                            li {
                                                a {
                                                    padding: 0 70px;
                                                }
                                            }
                                        }
                                    }
                                }

                                &.opensubchild {
                                    display: block;
                                }

                                li {
                                    a {
                                        padding: 0 45px;
                                    }
                                }
                            }
                        }
                    }

                    .mega-menu-container {
                        display: none;
                        padding: 0;
                        position: relative;
                        box-shadow: none;

                        &.opensubmenu {
                            display: block;
                        }

                        .mega-box {
                            width: 100%;
                            padding: 0;

                            .link-section {
                                .submenu-title {
                                    position: relative;

                                    h5 {
                                        margin-bottom: 0;
                                        font-weight: 400;
                                        line-height: 1.9;
                                        padding: 2px 25px;
                                    }

                                    .according-menu {
                                        top: 7px;
                                    }
                                }

                                .submenu-content {
                                    display: none;

                                    &.opensubmegamenu {
                                        display: block;
                                        padding: 0 45px;
                                    }

                                    ul {
                                        li {
                                            a {
                                                line-height: 1.9;

                                                &:hover {
                                                    margin-left: 0;

                                                    &:after {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                +.link-section {
                                    margin-top: 0;
                                }
                            }
                        }
                    }

                    .nav-link {
                        &.active {
                            background-color: transparent;
                            box-shadow: none;
                            color: $black1;
                            transition: all 0.5s ease;

                            &:hover {
                                color: var(--theme-color);
                                transition: all 0.5s ease;
                            }
                        }
                    }
                }

                .mobile-poster {
                    border-bottom: none !important;
                    margin: 0 !important;
                    position: fixed;
                    bottom: 0;
                    right: -410px;
                    width: 300px;
                    padding: 20px !important;
                    background-color: $light-blue;
                    align-items: center;
                    transition: all 0.3s ease;

                    img {
                        width: 50px;
                    }

                    .mobile-contain {
                        margin-left: 15px;

                        h5 {
                            font-weight: 600;
                            font-size: 15px;
                        }

                        p {
                            font-size: 13px;
                            margin: 7px 0;
                        }
                    }
                }
            }
        }

        .menu {
            nav {
                margin-left: auto;
                margin-right: 30px;
            }
        }
    }

    .mega-box {
        .link-section {
            .demo {
                ul {
                    li {
                        a {
                            span {
                                img {
                                    top: 30px;
                                    right: -60px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .mega-box {
        &.col {
            flex-basis: unset;
        }
    }

    .according-menu {
        position: absolute;
        right: 20px;
        top: 10px;

        [dir="rtl"] & {
            right: unset;
            left: 20px;
        }
    }
}