/**=====================
    category mixins css start
==========================**/
@mixin category_1 {
    .category-style-1 {
        width: 100%;
        position: relative;
        background-color: $red;

        img {
            width: 100px;
        }

        &.theme-bg-color {
            color: $white;

            .light-text {
                font-size: 72px;
                line-height: 96px;
                color: $white;
                font-weight: 700;
                margin-bottom: 0;
                position: absolute;
                opacity: 0.08;
                top: -10px;
                left: 0;
            }

            h2 {
                margin-bottom: 10px;
            }

            span {
                letter-spacing: 8px;
                text-transform: uppercase;
            }
        }

        .category-content {
            color: $black1;

            h3 {
                margin-bottom: 2px;
                font-weight: 600;
                font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
            }

            span {
                font-weight: 500;
            }

            &.category-spacing {
                h3 {
                    margin-top: -3px;
                }

                span {
                    margin-bottom: -3px;
                }
            }
        }
    }
}