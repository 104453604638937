/**=====================
    Touchspin  CSS Start
==========================**/
.bootstrap-touchspin {
    input {
        &.touchspin {
            padding: 0 12px;
        }
    }

    .input-group .btn {
        padding: 0.5rem 1.75rem;
    }

    .input-group {
        font-family: $font_0, $font_1;

        .btn {
            border-radius: 0 !important;
        }
    }
}

.touchspin-vertical-tab {
    max-width: 60px;
    width: unset;

    .touchspin-vertical {
        padding: 6px 12px;
    }

    .input-group {
        .input-group-btn-vertical {
            position: relative;
            display: grid;

            .bootstrap-touchspin-down {
                margin-top: -2px;
            }

            i {
                position: absolute;
                top: 4px;
                left: 7px;
                font-size: 10px;
                font-weight: 900;
            }

            >.btn {
                display: block;
                float: none;
                width: 100%;
                max-width: 100%;
                padding: 9px;
                margin-left: -1px;
                position: relative;
                background: $white;
                border-color: $border-color;
            }
        }
    }
}