:root {
    --theme-color: #0163d2;
}

.theme-color2 {
    --theme-color: #e22454;
}

.theme-color3 {
    --theme-color: #7520dd;
}

.theme-color4 {
    --theme-color: #e87316;
}

.theme-color5 {
    --theme-color: #df4246;
}

.theme-color6 {
    --theme-color: #51983c;
}

.text-color {
    color: #6d6d6d;
}

$white: #fff;
$black: #000;
$black1: #212529;
$gray: #c7c7c5;
$gray-light: #ddd;
$gray-90: #e5e5e5;
$light-blue: #eff2f7;
$theme-font-color: rgb(35, 35, 35);
$dark-color: #1d1d1d;
$font-dark: rgba($theme-font-color, 0.65);
$font-dark-30: rgba($theme-font-color, 1);
$rating-color: #facc21;
$font-light: #7e7e7e;
$light-button: #fefefe;
$demo1-bg: #f0f3f8;
$border-color: #ced4da;
$darksilver: #696969;
$lightsilver: #f8f8f8;
$theme-color: #7520dd;
$theme-light-color: #e3ddf4;
$red: #ff0000;
$yellow: #ffa202;
$background-color: #f7f7f7;
$light-text: #38383899;
$green: #009c00;
$light-theme-color: #eff3f7;
$red-40: rgba(255, 0, 0, 0.4);
$demo-light-2: #efdee7;
$demo-light-4: #eadfd5;
$light-demo-4: #eaeaea;
$demo-light-5: #eee1e6;
$light-demo-6: #f0f8f0;
$light-demo-7: #f0f7f0;
$light-red-shade: #e9e9e9;
$american-silver: #cecece;
$shade-gray: #777777;
$nero: #191919;
$demo-4: #e87316;
$theme-color1: #0163d2;
$green-dark: #0f5132;
$dark-green: #0c5460;
$light-green: #d1e7dd;
$american-gray: #d0d0d0;
$light-yellow: #fcba7f;
$light-gray: #c3c3c3;
$purple: #731fda;
$camouflage-green: #777876;
$half-baked: #86b8cf;
$monochromatic: #4b77a0;
$silver: #c0c0c0;
$dark-silver: #cacaca;
$gray-50: #ebebeb;
$dodger-blue: #88d1f4;
$light-red:#e3627b;
$light-purple: #a0768e;
$bright-gray: #efefef;
$gainsboro: #dedede;
$quick-silver: #a5a5a5;
$light-grayish-blue: #6c757d;
$dark-blue: #43609c;
$bermuda-grey: #78909c;
$green-dark: #008000;
$white-dark: #f2f9fc;
$light-shade-gray: #f9f9f9;
$dark-shade-gray: #d2d2d2;
$whisper: #f1e7e6;
$silver-original: #bfbfbf;
$silver-saturated: #aaaaaa;
$gray-saturated: #929292;
$chinese-silver: #c6c9cc;
$bright-silver-1: #ececec;
$silver-light: #b5bec7;
$gray-dark: #7f7f7f;
$gray-dark-1: #8f8397;
$chinese-silver-1: #e0e0e0;
$light-orange: #fab1a0;
$light-green-1: #10ac84;
$yellow-dark: #ff9f43;
$dark-shade-silver: #8395a7;
$black-olive: #3d3d3d;

$orange1: #ec7a1f;
$purple-gradient: linear-gradient(-23deg, #490c92 0%, #7b23e9 100%);
$red-gradient: linear-gradient(160deg, #a11d19 0%, #9d1c1e 100%);
$blue-gradient: linear-gradient(119deg, #0079db 0%, #001265 100%);
$theme-color-gradient: linear-gradient(160deg, #a11d19 0%, #9d1c1e 100%);
$red-50: rgba(255, 0, 0, 0.2);
$pink1: #df0f88;
$veg1: #51983c;

// font family
$font_0: Rubik;
$font_1: sans-serif;

$min-breakpoints: (lg: 992px,
    xl: 1200px,
    2xl: 1366px,
);

$max-breakpoints: (2xs: 360px,
    xs: 480px,
    sm: 575px,
    md: 767px,
    lg: 991px,
    xl: 1199px,
    2xl: 1366px,
    3xl: 1460px,
    4xl: 1660px,
);