/**=====================
     title css start
==========================**/

.title {
    margin-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));

    &.title1 {
        h2 {
            font-size: calc(22px + (35 - 22) * ((100vw - 320px) / (1920 - 320)));
            position: relative;
            display: inline-block;
            padding-bottom: 16px;

            &:before {
                @include pos;
                @include center(horizontal);
                bottom: 1.5px;
                width: 100%;
                height: 1px;
                background-color: $gray-light;
            }

            &:after {
                @include pos;
                @include center(horizontal);
                bottom: -1px;
                width: 30%;
                height: 4px;
                background-color: var(--theme-color);
                border-radius: 50px;
            }
        }

        p {
            margin: 20px auto 0;
            color: $black1;
            letter-spacing: 1px;
            width: 50%;
            line-height: 1.5;
        }
    }

    &.title-effect {
        h2 {
            &::after {
                left: 0;
                transform: translateX(0);
            }
        }
    }

    &.title-1 {
        margin-bottom: 0;
        padding: 0 15px;
    }

    h5 {
        margin-bottom: 10px;
        margin-top: -2px;
        color: $font-light;
    }
}

.title-2 {
    color: $black1;

    h2 {
        margin-top: -2px;
    }

    h3 {
        margin-top: -2px;
    }

    h5 {
        margin-bottom: 0;
        margin-top: 10px;
        color: $font-light;
    }
}

.title-3 {
    margin-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));

    &.title-border {
        border-bottom: 1px solid $demo1-bg;
    }

    h2 {
        margin-top: -2px;
    }

    h5 {
        margin-bottom: 0;
        margin-top: 10px;
    }
}

.gradient-title {
    background-image: linear-gradient(100deg, rgba(61, 61, 61, 1), var(--theme-color));
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    -webkit-background-clip: text;
    cursor: pointer;

    &-1 {
        background-image: linear-gradient(100deg, rgba($white, 1), var(--theme-color));
    }
}