/**=====================
     Breadcrumb css start
==========================**/
.breadcrumb-section {
    background-color: $light-blue;
    display: flex;
    text-align: center;
    position: relative;
    z-index: 0;

    h3 {
        font-size: calc(20px + (26 - 20) * ((100vw - 320px) / (1920 - 320)));
    }

    &-1 {
        background-color: $light-red-shade;
    }

    .breadcrumb-item {
        &.active {
            color: $font-light;
        }
    }

    .breadcrumb {
        margin-bottom: 0;
        justify-content: center;
        margin-top: 10px;
    }

    .circles {
        position: absolute;
        top: 0;
        left: 0;
        @include pseudowh;
        overflow: hidden;
        z-index: -1;

        li {
            position: absolute;
            display: block;
            list-style: none;
            width: 20px;
            height: 20px;
            background-color: var(--theme-color);
            animation: animate 25s linear infinite;
            bottom: -150px;
            opacity: 0.2 !important;

            &:nth-child(1) {
                left: 25%;
                width: 80px;
                height: 80px;
                animation-delay: 0s;
            }

            &:nth-child(2) {
                left: 10%;
                width: 20px;
                height: 20px;
                animation-delay: 2s;
                animation-duration: 12s;
            }

            &:nth-child(3) {
                left: 70%;
                width: 20px;
                height: 20px;
                animation-delay: 4s;
            }

            &:nth-child(4) {
                left: 40%;
                width: 60px;
                height: 60px;
                animation-delay: 0s;
                animation-duration: 18s;
            }

            &:nth-child(5) {
                left: 65%;
                width: 20px;
                height: 20px;
                animation-delay: 0s;
            }

            &:nth-child(6) {
                left: 75%;
                width: 90px;
                height: 90px;
                animation-delay: 3s;
            }

            &:nth-child(7) {
                left: 19%;
                width: 110px;
                height: 110px;
                animation-delay: 7s;
            }

            &:nth-child(8) {
                left: 50%;
                width: 25px;
                height: 25px;
                animation-delay: 15s;
                animation-duration: 45s;
            }

            &:nth-child(9) {
                left: 20%;
                width: 15px;
                height: 15px;
                animation-delay: 2s;
                animation-duration: 35s;
            }

            &:nth-child(10) {
                left: 85%;
                width: 110px;
                height: 110px;
                animation-delay: 0s;
                animation-duration: 11s;
            }
        }
    }

    @keyframes animate {
        0% {
            transform: translateY(0) rotate(0deg);
            opacity: 1;
            border-radius: 0;
        }

        100% {
            transform: translateY(-1000px) rotate(720deg);
            opacity: 0;
            border-radius: 50%;
        }
    }
}